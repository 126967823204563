$main-color: #f4f5f8;
$main-bg: #b4adaf;
$main-font-color: #000000;
$secondary-font-color: #000000;
$secondary-bg: rgba(255, 255, 255, 0.8);
$main-font: 'Montserrat', sans-serif;

@mixin secondaryTitle {
  font-weight: bold;
  color: $main-font-color;
  font-size: 18px;
}
@mixin boxShadowCells {
  box-shadow: 0 1px 1px 0 #00000040;
}
@mixin bottomCells {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  background: #f4f4f4;
  border-radius: 10px;
  padding: 2px;
  align-items: center;
  color: $secondary-font-color;
  font-size: 15px;
}
@mixin bottomContainers {
  background: $secondary-bg;
  width: 47%;
  padding: 20px 5px;
  border-radius: 10px;
  height: 200px;
  @include boxShadowCells();
}
@mixin bottomTitles {
  @include secondaryTitle();
  margin: 0px;
  text-align: center;
}
body {
  background: $main-bg;
  color: $main-font-color;
  font-family: $main-font;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  margin: auto;
}

.demo-bg {
  opacity: 0.8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  max-height: 100vh;
  object-fit: none;
}

.header_container {
  display: flex;
  text-align: center;
  flex-direction: column;
  color: $main-color;
  text-shadow: 1px 1px #0000008c;

  // img {
  //   width: 50px;
  // }

  .logo {
    width: 120px;
    position: absolute;
    top: 2%;
    left: 1%;
  }

  .title_container {
    display: flex;
    margin: auto;
    flex-shrink: 0;

    h1 {
      margin: 0px 15px;
      font-size: 50px;
      font-weight: bold;
      // text-decoration: underline;
    }
  }

  h5 {
    // @include secondaryTitle();
    font-size: 22px;
    margin: 0;
    margin-top: 35px;
  }
}

.splash-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  img {
    width: 250px;
  }

  #loadingBar {
    width: 50%;
    background-color: #fff;
  }

  #load {
    width: 1%;
    height: 20px;
    background-color: #e24795;
  }
}

.leaderboard_container {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;

  .title_container {
    @include secondaryTitle();
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 15px;

    span {
      width: 150px;
      text-align: center;
    }
  }
}

.leadercell_container {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  margin-bottom: 15px;
  background: $secondary-bg;
  border-radius: 10px;
  // padding: 10px 0px;
  min-height: 40px;
  position: relative;
  @include boxShadowCells();
  span {
    font-weight: bold;
    font-size: 15px;
    color: $secondary-font-color;
    width: 150px;
    text-align: center;
    padding: 8px;
    border-radius: 10px 0 0 10px;
  }
  img {
    width: 22px;
  }

  #leader-0 {
    background: #e3c066;
  }
  #leader-1 {
    background: #ababab;
  }
  #leader-2 {
    background: #ba6d27;
  }
}

.bottom_comtainer {
  width: 78%;
  margin: auto;
  margin-top: 0px;
  border-radius: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.kpi_container {
  @include bottomContainers();

  .kpi_cell {
    @include bottomCells();
    @include boxShadowCells();

    span {
      padding: 5px 15px;
    }
  }
}

.top_maids_container {
  @include bottomContainers();

  h2 {
    text-align: center;
    color: $secondary-font-color;
  }

  .top_maids_headers {
    display: flex;
    justify-content: space-around;
    color: $secondary-font-color;
    text-align: center;
    margin-top: 12px;
    font-weight: bold;
    font-size: 12px;
    span {
      width: 100px;
    }
  }
  .top_maid_cell {
    @include bottomCells();
    @include boxShadowCells();
    font-size: 12px;
    color: $secondary-font-color;
    text-align: center;
    span {
      width: 100px;
    }
    .trophy_name_container {
      display: flex;
      align-items: center;
    }
  }
}

.legend_container {
  @include bottomContainers();
}

.bottom_headers {
  @include bottomTitles();
}

.award_base_container {
  display: flex;
  justify-content: space-around;
  width: 95%;
  margin: 5px auto;

  .medal_group_container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .medal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .number_of_cleans {
      font-weight: 800;
    }

    img {
      margin: 8px;
      width: 50px;
    }
    span {
      color: $secondary-font-color;
    }
  }
}

.score_explanation {
  text-align: center;
  color: $secondary-font-color;
  font-size: 12px;
  margin: 0.5rem 0;
  span {
    margin-bottom: 2px;
  }
}

.disclaimer {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.top_maid_container {
  position: relative;
  background-image: url('./assets/images/trophy_big.svg');
  width: 290px;
  background-repeat: no-repeat;
  .top_maid_name {
    color: $secondary-font-color;
    text-align: center;
    margin-top: 50px;
  }
}
marquee {
  background: $secondary-bg;
  padding: 6px;
  margin-bottom: 6px;
  margin-top: 50px;
  color: $main-font-color;
  position: absolute;
  bottom: 0;
}

.sparkles {
  transform-origin: center;
  width: 2rem;
}

.sparkles-right {
  transform: rotate(100deg);
}

.sparkles .sparkles-two--st0:first-of-type {
  animation: sparkles-glowing-soft 3125ms infinite;
  animation-delay: 0ms;
  transform-origin: 75% 22%;
  opacity: 0.5;
}

.sparkles .sparkles-two--st0:last-of-type {
  animation: sparkles-pulse 10000ms infinite reverse linear;
  animation-delay: 85ms;
  transform-origin: 35% 70%;
}

@keyframes sparkles-pulse {
  91% {
    transform: scale3d(1, 1, 1);
  }

  94% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  97% {
    transform: scale3d(0.2, 0.2, 0.2);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes sparkles-glowing-soft {
  60% {
    opacity: 0.5;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes sparkles-glowing-hard {
  88% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  94% {
    opacity: 0.2;
    transform: scale(0.5) rotate(5deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

.fade-in-up {
  animation: fade-in-up 2125ms 1 forwards;
  animation-delay: 750ms;
}

@keyframes fade-in-up {
  0%,
  30% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cycle-in {
  animation: cycle-in 13125ms infinite forwards;
  animation-delay: 750ms;
}

@keyframes cycle-in {
  0% {
    opacity: 1;
  }

  6% {
    opacity: 0;
  }

  12%,
  100% {
    opacity: 1;
  }
}

#coins {
  position: absolute;
  top: 0;
}

footer {
  color: $main-color;
  font-size: 10px;
  padding: 20px;
  position: absolute;
  bottom: 5%;
  left: 0;
  font-weight: 600;
  p {
    margin: 2px;
  }
}

.last-updated {
  margin-top: 15px;
  padding: 0;
  font-size: 12px;
  color: white;
  font-weight: bold;
}
